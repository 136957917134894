<template>
  <div>
    <!-- search input -->
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="inventories"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Icon -->
          <span v-if="props.column.field === 'icon'" class="text-nowrap">
            <b-avatar
              :src="props.row.icon ? props.row.icon : ''"
              class="mx-1"
            />
          </span>

          <span v-else-if="props.column.field === 'createdAt'">
            <b-badge variant="light-success">
              {{ getFormattedData(props.row.createdAt) }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'updatedAt'">
            <b-badge variant="light-success">
              {{ getFormattedData(props.row.updatedAt) }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <b-button
              variant="primary"
              :to="`/hotel/${hotelID}/inventory-data/${props.row.RoomId}`"
              >Open Inventory</b-button
            >
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
  },
  data() {
    return {
      inventories: [],
      pageLength: 15,
      dir: false,
      hotelID: this.$route.params.hotelID,
      columns: [
        {
          label: "Inventory",
          field: "RoomName",
        },
        {
          label: "Last Updated",
          field: "updatedAt",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
    };
  },
  methods: {
    getInventoryData() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/hotel/inventories/getInventoryData`,
          {
            id: this.hotelID,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.inventories = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getFormattedData(date) {
      return moment(date).tz("Asia/Calcutta").format("DD-MM-YYYY HH:MM:SSa");
    },
    deleteAttraction(attraction) {
      console.log(attraction);
    },
  },
  created() {
    this.getInventoryData();
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>